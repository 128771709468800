import React from "react"

import { SmallHeroLayout } from "../components/layouts"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <SmallHeroLayout>
    <SEO title="404" />
    <h1>NOT FOUND</h1>
    <p>You just found a link that doesn&#39;t exist... the sadness.</p>
  </SmallHeroLayout>
)

export default NotFoundPage
